import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import sanityClient from "../sanityClient";
import { HERO_SECTION_QUERY } from "../queries";

export const HeroSection = () => {
  const [heroData, setHeroData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(HERO_SECTION_QUERY)
      .then((data) => {
        setHeroData(data);
      })
      .catch((error) => {
        console.error("Error fetching hero data:", error);
      });
  }, []);

  const handleSmoothScroll = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (!heroData) {
    return null;
  }

  const backgroundGifUrl = heroData.backgroundGif?.asset?.url;
  const words = heroData.title?.split(" ") || [];

  return (
    <section
      id="hero"
      className="relative bg-black text-white flex flex-col justify-center items-center px-6 pt-40 pb-10 overflow-hidden border-l border-r border-neon-green"
      style={{
        backgroundImage: backgroundGifUrl ? `url(${backgroundGifUrl})` : "none",
        backgroundSize: "auto",
        backgroundPosition: "center",
        backgroundRepeat: "repeat",
      }}
    >
      <div
        className="bg-white bg-opacity-5 backdrop-blur-md border border-neon-green rounded-lg p-12 m-auto flex flex-col gap-5 
        transition-all duration-300 hover:backdrop-blur-none hover:border-white"
      >
        <div className="relative z-10 w-full container flex flex-col justify-center text-center">
          <h1 className="text-xl md:text-3xl lg:text-5xl text-white font-tetris">
            <motion.span
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              className="inline-block"
            >
              {words[0]} {words[1]}
            </motion.span>
            <br />
            <motion.span
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.6 }}
              className="block"
            >
              {words[2]}
            </motion.span>
          </h1>
        </div>

        <p className="text-xs sm:text-sm z-10 rainbow-text font-tetris text-center">
          {heroData.subtitle}
        </p>

        <div className="relative z-10 flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-6 mt-4 w-full justify-center max-w-6xl">
          {heroData.buttons &&
            heroData.buttons.map((button, index) => (
              <a
                key={index}
                href={button.link || `#${button.target}`}
                className="text-xs sm:text-sm retro-btn w-fit mx-auto md:mx-0 md:w-auto text-center"
                onClick={(e) =>
                  button.target && handleSmoothScroll(e, button.target)
                }
              >
                {button.label}
              </a>
            ))}
        </div>
      </div>
    </section>
  );
};
