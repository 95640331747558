import React from "react";
import Slider from "react-slick";
import collabVideo1 from "../videos/bletapertace_kuizine.mp4";
import collabVideo2 from "../videos/bletapertace_kanuniibletes.mp4";
import collabVideo3 from "../videos/bletapertace_pjupju.mp4";
import collabVideo4 from "../videos/bletapertace_manovrim.mp4";
import collabVideo5 from "../videos/bletapertace_pameshire.mp4";
import collabVideo6 from "../videos/bletapertace-ereza_nuance.mp4";
import collabVideo7 from "../videos/devqa_ekstzi.mp4";
import collabVideo8 from "../videos/devqa_mulla.mp4";
import collabVideo9 from "../videos/devqa_psemaninat.mp4";
import collabVideo10 from "../videos/devqa_rrethanat.mp4";
import collabVideo11 from "../videos/gledi_betterthanme.mp4";
import collabVideo12 from "../videos/gledi_dinnerdate.mp4";
import collabVideo13 from "../videos/makzogaj_takishedit.mp4";
import collabVideo14 from "../videos/rob4cash_kick.mp4";
import collabVideo15 from "../videos/youngarti_goyard.mp4";
import collabVideo16 from "../videos/youngarti_toksike.mp4";

import "../CollabsSection.css";

export const CollabsSection = () => {
  const collabs = [
    {
      id: 1,
      name: "Kuizine",
      date: "January 2024",
      description: "bletapertace",
      video: collabVideo1,
    },
    {
      id: 2,
      name: "Kanuni i Bletes",
      date: "January 2024",
      description: "bletapertace",
      video: collabVideo2,
    },
    {
      id: 3,
      name: "Pju Pju",
      date: "January 2024",
      description: "bletapertace",
      video: collabVideo3,
    },
    {
      id: 4,
      name: "Manovrim",
      date: "January 2024",
      description: "bletapertace",
      video: collabVideo4,
    },
    {
      id: 5,
      name: "Pa meshire",
      date: "January 2024",
      description: "bletapertace",
      video: collabVideo5,
    },
    {
      id: 6,
      name: "Nuance",
      date: "June 2024",
      description: "bletapertace ft. EREZA",
      video: collabVideo6,
    },
    {
      id: 7,
      name: "Ekstzi",
      date: "December 2024",
      description: "Devqa",
      video: collabVideo7,
    },
    {
      id: 8,
      name: "Mulla",
      date: "October 2023",
      description: "Devqa",
      video: collabVideo8,
    },
    {
      id: 9,
      name: "Pse man inati",
      date: "September 2023",
      description: "Devqa",
      video: collabVideo9,
    },
    {
      id: 10,
      name: "Rrethanat",
      date: "September 2022",
      description: "Devqa",
      video: collabVideo10,
    },
    {
      id: 11,
      name: "Better than me",
      date: "July 2023",
      description: "Gledi",
      video: collabVideo11,
    },
    {
      id: 12,
      name: "Dinner Date",
      date: "October 2023",
      description: "Gledi",
      video: collabVideo12,
    },
    {
      id: 13,
      name: "Ta kishe dit",
      date: "November 2023",
      description: "Mak Zogaj",
      video: collabVideo13,
    },
    {
      id: 14,
      name: "Kick",
      date: "February 2024",
      description: "Rob4Cash",
      video: collabVideo14,
    },
    {
      id: 15,
      name: "Goyard",
      date: "July 2024",
      description: "Young Arti",
      video: collabVideo15,
    },
    {
      id: 16,
      name: "Toksike",
      date: "March 2024",
      description: "Young Arti",
      video: collabVideo16,
    },
  ];

  const settings = {
    centerMode: true,
    centerPadding: "0px",
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false,
          centerPadding: "40px",
        },
      },
    ],
  };

  return (
    <section
      id="collabs"
      className="bg-custom-bg text-white py-10 md:py-20 border-l border-r border-neon-green"
    >
      <div className="container px-4 md:px-6 mx-auto">
        <h2 className="main-title flex justify-center pb-4">
          Collaborations
        </h2>

        <Slider {...settings} className="collabs-carousel">
          {collabs.map((collab) => (
            <div key={collab.id} className="carousel-item">
              <div className="video-wrapper">
                <video className="w-full border border-neon-green" controls>
                  <source src={`${collab.video}#t=1`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="text-content">
                <h3 className="text-xl md:text-2xl mb-2">{collab.name}</h3>
                <p className="text-xs md:text-sm text-gray-300 mb-2">
                  {collab.date}
                </p>
                <p className="text-sm md:text-base font-tetris text-neon-green">
                  {collab.description}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};
