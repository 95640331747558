// sanityClient.js
import { createClient } from "@sanity/client";

const sanityClient = createClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: process.env.REACT_APP_SANITY_DATASET,
  useCdn: true,
  apiVersion: "2023-01-01",
});

export default sanityClient;
