import React, { useEffect, useState } from "react";
import sanityClient from "../sanityClient";
import { ABOUT_SECTION_QUERY } from "../queries";

export const AboutSection = () => {
  const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(ABOUT_SECTION_QUERY)
      .then((data) => {
        setAboutData(data);
      })
      .catch((error) => {
        console.error("Error fetching about section data:", error);
      });
  }, []);

  if (!aboutData) {
    return null;
  }

  const { title, description } = aboutData;

  const renderDescription = (blocks) => {
    return blocks.map((block, index) => {
      if (block._type === "block") {
        return (
          <p key={index} className="text-sm sm:text-base md:text-lg mx-auto pt-10">
            {block.children.map((child, idx) => {
              if (child.marks && child.marks.includes("strong")) {
                return (
                  <span
                    key={idx}
                    className="font-tetris text-sm text-neon-green"
                  >
                    {child.text}
                  </span>
                );
              }
              return child.text; 
            })}
          </p>
        );
      }
      return null;
    });
  };

  return (
    <section
      id="about"
      className="bg-black text-white border-l border-r border-b border-neon-green py-20 pb-32"
    >
      <div className="container px-6 mx-auto">
        <h2 className="main-title">
          {title}
        </h2>
        {renderDescription(description)}
        <div className="relative z-10 flex justify-center md:float-right mt-8 md:mt-4">
          <a href="#contact" className="retro-btn">
            Contact Us
          </a>
        </div>
      </div>
    </section>
  );
};