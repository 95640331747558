import React from "react";
import smwBlockGif from "../img/smw-block.gif";
import artistsGif from "../img/artists.gif";

export const ArtistsSection = () => {
  return (
    <section
      id="artists"
      className="relative bg-black text-white border-l border-r border-neon-green py-20 pb-32"
    >
      <div
        className="absolute inset-0 bg-cover bg-center bg-no-repeat filter blur-sm z-0 opacity-50"
        style={{ backgroundImage: `url(${artistsGif})` }}
      ></div>

      <div className="relative z-10 container px-6 mx-auto">
        <h2 className="main-title flex justify-center">
          Our Artists
        </h2>

        <div className="flex flex-row items-center justify-center my-10">
          <img
            src={smwBlockGif}
            alt="Coming Soon"
            className="w-16 h-16 md:w-20 md:h-20"
          />
          <img
            src={smwBlockGif}
            alt="Coming Soon"
            className="w-16 h-16 md:w-20 md:h-20"
          />
          <img
            src={smwBlockGif}
            alt="Coming Soon"
            className="w-16 h-16 md:w-20 md:h-20"
          />
        </div>

        <h2 className="main-title flex justify-center">
          Coming soooooooon...
        </h2>
      </div>
    </section>
  );
};
