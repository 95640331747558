import React, { useState, useEffect } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { GrContact } from "react-icons/gr";
import { motion, AnimatePresence } from "framer-motion";
import logo from "../img/logo-2.png";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrollingUp, setIsScrollingUp] = useState(true);
  const [lastScrollPos, setLastScrollPos] = useState(0);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  // Handle scroll-up behavior for large screens only
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      // Only apply scroll logic on large screens (>= 1024px)

      if (currentScrollPos < lastScrollPos || currentScrollPos === 0) {
        setIsScrollingUp(true);
      } else {
        setIsScrollingUp(false);
      }
      setLastScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollPos]);

  const scrollToTarget = (targetId) => {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <nav
      className={`fixed top-0 left-0 w-full bg-black text-white shadow-md z-50 border border-neon-green transition-transform duration-300 ease-in-out ${
        isScrollingUp ? "" : "-translate-y-full"
      }`}
    >
      <div className="container mx-auto flex justify-between items-center py-4 px-6">
        {/* Logo */}
        <div className="w-20 h-20">
          <img src={logo} alt="Narcizem Logo" className="object-contain" />
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden z-20">
          <button onClick={toggleMenu} aria-label="Toggle Menu">
            {isMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
          </button>
        </div>

        {/* Desktop Links */}
        <div className="hidden md:flex space-x-12 font-tetris text-xs">
          <NavLinks closeMenu={closeMenu} scrollToTarget={scrollToTarget} />
        </div>

        {/* Contact Button */}
        <button
          onClick={() => scrollToTarget("contact")}
          className="hidden md:block text-lg text-white hover:text-neon-green"
        >
          <GrContact size={24} />
        </button>
      </div>

      {/* Mobile Fullscreen Menu */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            className="md:hidden fixed inset-0 bg-black z-10 flex flex-col justify-center items-center space-y-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <NavLinks closeMenu={closeMenu} scrollToTarget={scrollToTarget} />
            <button
              onClick={() => {
                scrollToTarget("contact");
                closeMenu();
              }}
              className="text-lg text-white hover:text-neon-green"
            >
              <GrContact size={24} />
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

const NavLinks = ({ closeMenu, scrollToTarget }) => (
  <>
    <NavLink
      label="Home"
      targetId="hero"
      closeMenu={closeMenu}
      scrollToTarget={scrollToTarget}
    />
    <NavLink
      label="About"
      targetId="about"
      closeMenu={closeMenu}
      scrollToTarget={scrollToTarget}
    />
    <NavLink
      label="Crew"
      targetId="crew"
      closeMenu={closeMenu}
      scrollToTarget={scrollToTarget}
    />
    <NavLink
      label="Artists"
      targetId="artists"
      closeMenu={closeMenu}
      scrollToTarget={scrollToTarget}
    />
    <NavLink
      label="Collabs"
      targetId="collabs"
      closeMenu={closeMenu}
      scrollToTarget={scrollToTarget}
    />
  </>
);

const NavLink = ({ label, targetId, closeMenu, scrollToTarget }) => {
  const handleClick = (e) => {
    e.preventDefault();
    scrollToTarget(targetId);
    closeMenu();
  };

  return (
    <div className="relative group">
      <a
        href={`#${targetId}`}
        onClick={handleClick}
        className="uppercase tracking-widest neon-green"
      >
        {label}
      </a>
      <div className="absolute left-0 bottom-[-2px] h-[2px] w-full bg-neon-green scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></div>
    </div>
  );
};

export default Navbar;
