import React, { useEffect, useState } from "react";
import { CREW_LIST_QUERY } from "../queries";
import sanityClient from "../sanityClient";

export const CrewSection = () => {
  const [crewMembers, setCrewMembers] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(CREW_LIST_QUERY)
      .then((data) => {
        setCrewMembers(data);
      })
      .catch((error) => {
        console.error("Error fetching crew data:", error);
      });
  }, []);

  return (
    <section
      id="crew"
      className="bg-custom-bg text-white py-20 border-l border-r border-neon-green"
    >
      <div className="container px-6 mx-auto">
        <h2 className="main-title pb-4">
          Crew
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {crewMembers.map((member) => (
            <div
              key={member._id} 
              className="bg-black border-4 border-neon-green p-4 flex flex-col items-center space-y-4 text-center retro-square transition-transform duration-300 hover:scale-105 hover:border-white hover:shadow-neon hover:bg-neon-green/10"
            >
              <img
                src={member.image.asset.url} 
                alt={member.name}
                className="w-24 h-24 md:w-32 md:h-32 object-cover border border-neon-green"
              />
              <h3 className="text-sm md:text-base font-tetris text-white">
                {member.name}
              </h3>
              <p className="text-neon-green text-xs font-tetris">
                {member.position}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};