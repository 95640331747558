export const HERO_SECTION_QUERY = `
  *[_type == "heroSection"][0] {
    title,
    subtitle,
    backgroundGif {
      asset-> {
        url
      }
    },
    buttons[] {
      label,
      target,
      link
    }
  }
`;

export const ABOUT_SECTION_QUERY = `
  *[_type == "aboutSection"][0] {
    title,
    description[] {
      ...,
      marks
    }
  }
`;

export const CREW_LIST_QUERY = `
  *[_type == "crewList"] | order(_createdAt asc) {
    name,
    position,
    image {
      asset -> {
        url
      }
    }
  }
`;