import React, { useState } from "react";
import emailjs from "emailjs-com";
import gif from "../img/eye.gif";
import notificationSuccessImg from "../img/check1.png";
import notificationFailImg from "../img/error.png";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    from_name: "",
    reply_to: "",
    message: "",
  });

  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const [showFailNotification, setShowFailNotification] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_zvbbz9o",
        "template_g6frpj8",
        formData,
        "SnEhK_Ms8ppGDLsso",
      )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setShowSuccessNotification(true);
        setTimeout(() => setShowSuccessNotification(false), 2000);
      })
      .catch((err) => {
        console.log("FAILED...", err);
        setShowFailNotification(true);
        setTimeout(() => setShowFailNotification(false), 2000);
      });
  };

  return (
    <div
      id="contact"
      className="relative flex flex-col lg:flex-row justify-center px-6 py-10 md:py-20 mx-auto space-y-8 lg:space-y-0 lg:space-x-8 border border-neon-green"
    >
      {showSuccessNotification && (
        <div className="absolute top-8 left-1/2 transform -translate-x-1/2 bg-black p-4 border-2 border-neon-green flex items-center space-x-4 z-50 w-full justify-center md:w-fit">
          <img
            src={notificationSuccessImg}
            alt="Success"
            className="w-12 h-12"
          />
          <span className="text-neon-green font-tetris">
            Message sent successfully!
          </span>
        </div>
      )}

      {showFailNotification && (
        <div className="absolute top-8 left-1/2 transform -translate-x-1/2 bg-black p-4 border-2 border-neon-red flex items-center space-x-4 z-50 w-full justify-center md:w-fit">
          <img src={notificationFailImg} alt="Failed" className="w-12 h-12" />
          <span className="text-neon-red font-tetris">
            Message failed, try again!
          </span>
        </div>
      )}

      <div className="">
        <h2 className="main-title pb-4">
          Reach out to us
        </h2>
        <form
          onSubmit={sendEmail}
          className="bg-black text-white retro-form font-tetris text-center md:text-left text-sm md:text-base"
        >
          <div className="mb-4">
            <label className="block rainbow-text mb-2 text-sm" htmlFor="from_name">
              YOUR NAME
            </label>
            <input
              type="text"
              id="from_name"
              name="from_name"
              value={formData.from_name}
              onChange={handleChange}
              required
              className="w-full p-2 border bg-static text-white focus:outline-none text-xs md:text-sm"
            />
          </div>
          <div className="mb-4">
            <label className="block rainbow-text mb-2 text-sm" htmlFor="reply_to">
              YOUR EMAIL
            </label>
            <input
              type="email"
              id="reply_to"
              name="reply_to"
              value={formData.reply_to}
              onChange={handleChange}
              required
              className="w-full p-2 border bg-static text-white focus:outline-none text-xs md:text-sm"
            />
          </div>
          <div className="mb-4">
            <label className="block rainbow-text mb-2 text-sm" htmlFor="message">
              MESSAGE
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              className="w-full p-2 border bg-static text-white focus:outline-none text-xs md:text-sm"
            />
          </div>
          <button
            type="submit"
            className="bg-neon-green text-black px-4 py-2 retro-btn mt-4 md:mt-8"
          >
            Send to Narcizem
          </button>
        </form>
      </div>

      <div className="hidden lg:block lg:max-w-sm">
        <img src={gif} alt="Eye Gif" className="w-full object-contain" />
      </div>
    </div>
  );
};

export default ContactForm;
